class DragDropit{
    constructor( selector ){

        this.element = document.querySelector( selector );
        if(!this.element){
            // this.sendEvent('no-element',this.element);
            return false;
        }
        this.init();
        return this;
    }

    init=()=>{
        this.sendEvent('init',this.element);
        this.attachEvents();
        this.element.classList.add('is-dragdropable');
    }
    attachEvents=()=>{
        this.removeDefaultEvents();
        this.element.addEventListener('dragenter', this.highlight,false);
        this.element.addEventListener('dragover',  this.highlight,false);

        this.element.addEventListener('dragleave', this.unHighlight,false);
        this.element.addEventListener('drop',      this.unHighlight,false);
        this.element.addEventListener('drop',      this.handleDrop,false);
    }
    highlight=()=>{
        this.element.classList.add('dropit-highlighted');
        this.sendEvent('highlight',this.element);
    }
    unHighlight=()=>{
        this.element.classList.remove('dropit-highlighted');
        this.sendEvent('unHighlight',this.element);
    }
    handleDrop=( event )=>{
        let dt = event.dataTransfer;
        let files = dt.files;
        this.sendEvent('drop',files);
        return files;
    }
    removeDefaultEvents=()=>{
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            this.element.addEventListener(eventName, this.preventDefaults, false)
            document.body.addEventListener(eventName, this.preventDefaults, false)
          })
    }
    preventDefaults=( event )=>{
        event.preventDefault();
        event.stopPropagation();
    }
    sendEvent(name,data){
        let custom_event = new CustomEvent( `dropit.${name}`, {
            bubbles: false,
            detail: data
        })
        this.element.dispatchEvent( custom_event );
    }

}
export default DragDropit;