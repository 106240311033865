<template>
    <modal type="full-screen" v-show="login_modal_visible" :show_the_content="login_modal_visible" :show_close_button="$route.name !== 'AAA_browse'"
        @close="hide_modal" class="centered-modal authenitcation-modal" id="filmstro-login-modal">
        <template v-slot:body>

            <div class="logo-bar">
                <a href="#"><img src="https://filmstro.com/wp-content/plugins/simple_auth0_login/assets/filmstro-logo.png"
                        alt="filmstro-logo"></a>
            </div>

            <div class="login-parent">

                <div class="col auth-modal-left-col col-md-6 col-lg-6 col-xl-7">
                    <div class="auth-modal-left">
                        <div class="auth-modal-text" id="login-modal-list">
                            <h1>Music for anything</h1>
                            <p>Make professional soundtracks in minutes</p>
                            <ul>
                                <li>Unlimited use of our award winning soundtrack app</li>
                                <li>Unlimited downloads of royalty free music</li>
                                <li>Exclusive curated content</li>
                                <li>100% royalty free music</li>
                                <li>Worldwide cover - for ever</li>
                            </ul>
                        </div>
                        <div v-if="false" class="auth-modal-text" id="signup-modal-list">
                            <h1>Music for anything</h1>
                            <p>With a free account you get:</p>
                            <ul>
                                <li>Unlimited FREE music for personal use incl. monetized YouTube</li>
                                <li>Unlimited use of our award winning soundtrack app</li>
                                <li>Exclusive, high-quality &amp; curated content</li>
                                <li>Worldwide cover - for ever</li>
                                <li>100% royalty free music</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col auth-modal-right-col col-md-6 col-lg-6 col-xl-5">


                    <div v-show="auth_view == 'login'" class="auth-modal-form active" id="login-modal">

                        <div class="auth-modal-form-header">
                            <h2>Welcome Back</h2>
                            <h3>Start creating</h3>
                            <div style="display:none;" v-if="false" >
                                <a href="#" class="google-btn"><img
                                        src="https://filmstro.com/wp-content/plugins/simple_auth0_login/assets/google-logo.png"
                                        alt="google-logo"> Continue with Google</a>
                                <span class="login-part">
                                    <h4>Or continue with email</h4>
                                </span>
                            </div>
                        </div>

                        <div class="auth-modal-form-inner">
                            <!--  -->
                            <form id="login-form" @submit.prevent="login" action="" method="post" novalidate="novalidate" :class="{'submitting': loading }">
                                <div class="form-group" :class="{ 'has-error': errors.email }">
                                    <label class="form-label" for="email">Email Address</label>
                                    <input @input="validate_field('email',$event)" v-model="email" type="email" name="user_email" class="form-control required"
                                        placeholder="" aria-required="true" required>
                                    <div class="input-error" v-if="errors.email">{{ errors.email }}</div>
                                </div>
                                <div class="form-group" :class="{ 'has-error': errors.password }">
                                    <label class="form-label" for="password">Password</label>
                                    <input @input="validate_field('password',$event)" v-model="password" type="password" name="user_password"
                                        class="form-control required" placeholder="" aria-required="true">
                                        <div class="input-error" v-if="errors.password">{{ errors.password }}</div>
                                </div>

                                <button :disabled="( errors.email || errors.password ) || ( !email.length || !password.length) || loading" type="submit" class="btn login-btn" :class="{'loading':loading}">
                                    <span class="button__text">Log In</span>
                                </button>

                                <div class="response-message" :class="[response.login.type]">{{ response.login.message }}</div>
                            </form>
                            <div class="loginform-text">
                                <div class="form-group text-center my-4">
                                    <p><a href="#" @click="auth_view = 'reset-password'"
                                            class="blue-text noredirect lp-modal-toggle">Forgotten your password?</a></p>
                                </div>
                                <!-- <p>by clicking “Sign up”  you agree to our <a href="#">ToS</a> and <a href="#">Privacy Policy</a></p> -->
                                <p>Do Not Have an account? <a @click="auth_view = 'signup'"
                                        class="back-to-login register-modal-toggle">Sign up now</a></p>
                            </div>
                        </div>
                    </div>
                    <div v-show="auth_view == 'signup'" class="auth-modal-form" id="signup-modal">

                        <div class="auth-modal-form-header">
                            <h2>Get started for free</h2>
                            <h3>No credit card required</h3>
                            <div style="display:none;" v-if="false">
                                <a href="#" class="google-btn"><img
                                        src="https://filmstro.com/wp-content/plugins/simple_auth0_login/assets/google-logo.png"
                                        alt="google-logo"> Continue with Google</a>
                                <span class="login-part">
                                    <h4>Or continue with email</h4>
                                </span>
                            </div>
                        </div>

                        <div class="auth-modal-form-inner">
                            <form id="signup-form" @submit.prevent="registerUser" action="" method="post" novalidate="novalidate" :class="{'submitting': loading }">
                                <div class="form-group" :class="{ 'has-error': errors.email }">
                                    <label class="form-label" for="email">Email Address</label>
                                    <input @input="validate_field('email',$event)"  v-model="email" type="email" name="email" class="form-control required" placeholder="" aria-required="true">
                                    <div class="input-error" v-if="errors.email">{{ errors.email }}</div>
                                </div>
                                <div class="form-group" :class="{ 'has-error': errors.full_name }">
                                    <label class="form-label" for="name">Full Name</label>
                                    <input @input="validate_field('full_name',$event)" v-model="full_name"  type="text" name="full_name" class="form-control required" placeholder="" aria-required="true">
                                    <div class="input-error" v-if="errors.full_name">{{ errors.full_name }}</div>
                                </div>
                                <div class="form-group" :class="{ 'has-error': errors.password }">
                                    <label class="form-label" for="password">Password</label>
                                    <input @input="validate_field('password',$event)" v-model="password" type="password" name="password" class="form-control required" placeholder="" aria-required="true">
                                    <div class="input-error" v-if="errors.password">{{ errors.password }}</div>
                                </div>
                                <button :disabled="( errors.email || errors.password || errors.full_name ) || ( !email.length || !password.length) || loading" id="signup-button" type="submit" class="btn login-btn" :class="{'loading':loading}" value="Sign up">
                                    <span class="button__text">Sign up</span>
                                </button>
                            </form>
                            <div class="response-message" :class="[response.register.type]">{{response.register.message}}</div>
                            <div class="loginform-text">
                                <p>by clicking “Sign up” you agree to our <a href="/terms-and-conditions">ToS</a>
                                    and <a href="/privacy-policy">Privacy Policy</a></p>
                                <p>Have an account? <a @click="auth_view = 'login'" class="back-to-login login-modal-toggle">
                                        Login</a></p>
                            </div>
                        </div>

                    </div>
                    <div v-show="auth_view == 'reset-password'" class="auth-modal-form" id="password-reset-modal">

                        <div class="auth-modal-form-header">
                            <h2>Reset your password</h2>
                        </div>

                        <div class="auth-modal-form-inner">

                            <form id="reset-password-form" @submit.prevent="resetPassword" action="" method="post" novalidate="novalidate" :class="{'submitting': loading }">

                                <div class="form-group">
                                    <label class="form-label" for="email">Email Address</label>
                                    <input @input="validate_field('email',$event)"  v-model="email" required="" type="email" name="user_email" id="email" placeholder="Email" class="form-control required email" aria-required="true">
                                    <div class="input-error" v-if="errors.email">{{ errors.email }}</div>
                                </div>

                                <button :disabled="( errors.email ) || ( !email.length ) || loading" class="btn login-btn" :class="{'loading':loading}">
                                    <span class="button__text">Send reset Email</span>
                                </button>
                            </form>

                            <div class="response-message" :class="[response.password_reset.type]">{{response.password_reset.message}}</div>
                            <div class="loginform-text">
                                <!-- <p>by clicking “Sign up”  you agree to our <a href="#">ToS</a> and <a href="#">Privacy Policy</a></p> -->
                                <p><a @click="auth_view = 'login'" class="back-to-login login-modal-toggle">Back to Login</a>
                                </p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

        </template>
    </modal>
</template>


<script setup>
import { auth_base_url,validateEmail } from '@/includes/helpers';
import { mapGetters, useStore } from 'vuex';
import { ref, computed } from 'vue';
import * as Sentry from "@sentry/vue";

const store = useStore();
const auth_view = ref("login");

const email = ref( "" );
const password = ref( "" );
const full_name = ref( "" );
const errors = ref({
    email: "",
    message:"",
    password: "",
    full_name: ""
});
const response = ref({
    login:{
        type:"error",
        message:""
    },
    register:{
        type:"error",
        message:"",
    },
    password_reset:{
        type:"error",
        message:""
    }
})

const login_modal_visible = computed(() => store.getters.login_modal_visible)
const loading = ref( false );

const hide_modal = () => {
    store.commit('setLoginModalVisible', false);
}


const validate_field = ( field, event)=>{
    let val = event.target.value;
    if( !val.length ){
        let field_name = field.replace('_'," ");
        errors.value[field] = `${field_name} field is required.`;
        return false;
    }
    errors.value[ field ] = null;
    return true;
}

const resetForm = (form = false )=>{
    email.value = "";
    password.value = ""
    if( form =='signup' ){
        full_name.value = "";
    }
}

const registerUser = async ()=>{
    if( !validateEmail( email.value ) ){
        errors.value.email = "Not a valid email address"
        return false;
    }
    response.value.register.type = null;
    response.value.register.message = null;

    let _data = {
        email: email.value,
        password: password.value,
        full_name:full_name.value,
        device: btoa( navigator.userAgent )
    }
    loading.value = true;

    await store.dispatch('register', _data).then(data=>{
        try{
            if( !data.status || data.status == 'fail' ){

                if( data.status !=='success' ){
                if( data.message ){
                    response.value.register.type = 'error';
                    response.value.register.message = data.message;
                }
                return;
            }else{
                response.value.register.type = 'success';
                response.value.register.message = `Welcome back ${store.getters?.CURRENT_USER?.first_name}`;

                hide_modal();
            }

                return;
            }else{
                response.value.register.type = 'success';
                response.value.register.message = `Welcome ${store.getters?.CURRENT_USER?.first_name}!`;
                setTimeout(() => {
                    resetForm( 'signup' );
                }, 1000);
                hide_modal();
            }
        }catch( e ){
            Sentry.captureException( e )
        }

    }).catch(e=>{
        Sentry.captureException( e )
    })
    loading.value = false;
}
const resetPassword = async ()=>{
    if( !validateEmail( email.value ) ){
        errors.value.email = "Not a valid email address"
        return false;
    }
    response.value.password_reset.type = null;
    response.value.password_reset.message = null;

    let _data = {
        email: email.value,
        device: btoa( navigator.userAgent )
    }
    loading.value = true;

    await store.dispatch('resetPassword', _data).then(data=>{

        try{
            if( !data.status || data.status == 'fail' ){

                if( data.message ){
                    response.value.password_reset.type = 'error';
                    response.value.password_reset.message = data.message;
                }
                return;
            }else{
                if( data.message ){
                    response.value.password_reset.type = 'success';
                    response.value.password_reset.message = data.message;
                }
            }
        }catch( e ){
            Sentry.captureException( e )
        }

    }).catch(e=>{
        Sentry.captureException( e )
    })
    loading.value = false;
}
const login = async () => {

    if( !validateEmail( email.value ) ){
        errors.value.email = "Not a valid email address"
        return false;
    }
    response.value.login.type = '';
    response.value.login.message = '';
    let _data = {
        email: email.value,
        password: password.value,
    }
    loading.value = true;
    // fetch('https://development.filmstro.com/wp-admin/admin-ajax.php',{
    //     method:"POST",
    //     body:JSON.stringify({
    //         action:'ajax_login',
    //         user_email:email.value,
    //         user_password:password.value
    //     })
    // });
    // return;
    await store.dispatch('login', _data).then(data=>{
        try{
            if( data.status !=='success' ){
                if( data.message ){
                    response.value.login.type = 'error';
                    response.value.login.message = data.message;
                }

                return;
            }else{
                response.value.login.type = 'success';
                response.value.login.message = `Welcome back ${store.getters?.CURRENT_USER?.first_name}`;
                setTimeout(() => { hide_modal() }, 1000);
                store.dispatch('fetchUserSessions', true);
            }
        }catch( e ){
            Sentry.captureException( e )
        }

    }).catch(e=>{
        Sentry.captureException( e )
    })
    loading.value = false;
}



</script>

<style lang="scss">
/* Filmstro Modal */
#filmstro-login-modal {
    z-index: 9999999999999999;
    .modal-content {
        max-width: 100%;
    }

    .modal-dialog {
        width: 100%;
        max-width: 990px;
        margin: 0 auto;
    }



    .modal-body {
        padding: 0;
        text-align: left;
    }



    .auth-modal-form {
        padding: 40px 55px 45px 55px;
        background-color: #fff;
        border-radius: 15px;
        margin: 0 auto;
        max-width: 448px;
        width: 100%;
        // transform: scale(0.8) translateX(40px);
        // opacity: 0;
        // visibility: hidden;
        transition: all .4s ease;
        // display: none;
    }

    .login-parent {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        background-image: url(https://filmstro.com/wp-content/plugins/simple_auth0_login/assets/auth-modal-bg.jpg);
        background-size: cover;
        background-position: center;
        padding: 40px;
    }

    h1 {
        font-size: 54px;
        color: #fff;
        font-weight: 800;
        margin: 0 0 0 0;
    }

    h2 {
        font-size: 22px;
        font-weight: 800;
        color: #0f1113;
        text-align: center;
    }

    h3 {
        font-size: 16px;
        margin: 5px;
        color: #0f1113;
        text-align: center;
    }

    p {
        font-size: 24px;
        color: #fff;
        font-weight: 800;
        margin: 0 0 10px 0;
    }

    .auth-modal-text ul {
        list-style: none;
        margin: 48px 0 0 0;
    }

    li {
        padding: 0 0 0 50px;
        font-size: 21px;
        line-height: 44px;
        position: relative;

        &::before {
            content: "";
            width: 21px;
            height: 21px;
            background-image: url(https://filmstro.com/wp-content/plugins/simple_auth0_login/assets/check-icon.png);
            background-size: cover;
            position: absolute;
            top: 15px;
            left: 0;
        }
    }

    .auth-modal-left {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        min-height: 100%;
    }

    .auth-modal-form-header {
        margin-bottom: 30px;
    }






    @media(max-width:768px) {}



    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .row>* {
        box-sizing: border-box;
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
    }

    .col {
        flex: 1 0 0%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        overflow: hidden;
    }

    .modal button.close {
        background: none;
        border: 0;
        font-size: 30px;
        right: 40px;
        top: 10px;
    }

    .modal-body h4 {
        text-align: center;
    }

    .auth-modal-form-inner label.form-label {
        font-size: 14px;
        color: #0f1113;
        margin: 0 0 -11px 10px;
        padding: 0 7px;
        display: table;
        background-color: #fff;
        z-index: 1;
        position: relative;
    }

    .form-control {
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #0f1113;
        font-size: 16px;
        color: #0f1113;
        padding-left: 20px;
        text-align: left;
        height: 46px;
        box-shadow: none;
    }

    .loginform-text p {
        margin: 15px 0 0 0;
        color: #777c85;
        font-size: 14px;
        text-align: center;
        font-weight: normal;
    }

    .login-btn {
        background: #ed4865 !important;
        width: 100%;
        color: #fff;
        font-size: 15px;
        border: none;
        margin-top: 15px;
        border-radius: 100px;
        position: relative;
        &:disabled{
            opacity: .7;
        }

        &.loading{
            span{
                opacity: 0;
            }
            &::after {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                border: 4px solid transparent;
                border-top-color: #ffffff;
                border-radius: 50%;
                animation: button-loading-spinner 1s ease infinite;
            }
        }
    }

    .back-to-login {
        color: #0f1113 !important;
        font-weight: 600 !important;
        cursor: pointer !important;
    }

    .form-group{
        margin-bottom: 10px;
        &.has-error{
            color: red;
            label.form-label{
                color: red;
            }
            .form-control{
                border-color: red;
            }
        }
    }


    .input-error {
        font-size: 10px;
        color: red;
        &::first-letter{
            text-transform: capitalize;
        }
    }

button{
    cursor: pointer;
}


.response-message {
    color: black;
    margin-top: 7px;
    font-size: 14px;
    &.error {
        color: red;
    }
    &.success {
        color: green;
    }
}




}


.authenitcation-modal .btn-close {
    color: white !important;
}
</style>