export default{
    creatted(el, binding) {
        console.log('Created....')  ;
    },
    mounted(el, binding) {
        // console.log('Mounted....')  ;


        el.addEventListener('mouseenter', () => {
            const demoElement = document.getElementById('tooltips-wrapper');
            if ( demoElement ) {
                let v = binding.value;
                if( typeof v == 'object' && v !== null ){
                    demoElement.innerHTML = binding.value.content;
                }else{
                    demoElement.innerHTML = v;
                }
            }
        })

        el.addEventListener('mouseleave', () => {
            // if ( demoElement ) {
            //     demoElement.innerHTML = binding.value;
            // }
        })
    }
}
