import {API} from "@/includes/API";
import { auth_base_url } from '@/includes/helpers';
import * as Sentry from "@sentry/vue";
const  api = new API( auth_base_url() )
// API.base = auth_base_url();
const STORAGE_KEYS = {
    USER_DATA:'flm_u',
    TOKEN_NAME:'flm_tkn'
}
const get_default_user_object = ()=>{
    return {
        user_id:false,
        license:{
            active:false,
            type:false
        }
    }
}

const maybe_get_user_from_token = ( token )=>{
    if( !token ){
        token = localStorage.getItem( STORAGE_KEYS.USER_DATA );
    }
    if( token ){

        try{
            let user_data  = JSON.parse( atob( token ) )
            if( user_data ){
                if(user_data &&  user_data.active_license ){
                    window.filmstro_use_watermark = false;
                  }else{
                    window.filmstro_use_watermark = true ;
                }
                Sentry.setUser({ email: user_data.email, id: user_data.id,is_active_perlat:'yes-indeed' });
                return user_data;
            }
        }
        catch(e){
        }
    }
    return get_default_user_object();
}


export default {
    // namespaced:true,
    state:{
        user: maybe_get_user_from_token(),
        token: localStorage.getItem( STORAGE_KEYS.TOKEN_NAME ) || null
    },
    getters:{
        CURRENT_USER:(state=>state.user),
        GET_TOKEN: state=>state.token,
        USER_IS_LOGGED_IN( state){
            return state.user && state.user.user_id;
        },
        LOGGED_IN_USER_ID( state){
            return state.user.user_id;
        },
        LOGGED_IN_USER_IS_ADMIN( state){
            return state.user.user_id && state.user.is_admin;
        },
    },
    mutations:{
        SET_USER_DETAILS:(state,data)=>{
            state.user = data;
        },
        SET_TOKEN: (state,data )=>{
            state.token = data;
            localStorage.setItem( STORAGE_KEYS.TOKEN_NAME, data );
        }
    },
    actions:{
        async maybe_set_token_and_user ( context, data){
            if(data.token){
                context.commit('SET_TOKEN', data.token )
                window.Cookie.set( STORAGE_KEYS.TOKEN_NAME, data.token, 30 );
                if( data.user ){
                    let user_data  = JSON.parse( atob( data.user ) )
                    if( user_data ){
                        context.commit('SET_USER_DETAILS', user_data )
                        localStorage.setItem( STORAGE_KEYS.USER_DATA, data.user );
                    }
                }
            }
        },
        async logout(context){
            localStorage.removeItem( STORAGE_KEYS.USER_DATA );
            localStorage.removeItem( STORAGE_KEYS.TOKEN_NAME );
            window.Cookie.erase( STORAGE_KEYS.TOKEN_NAME );
            window.Cookie.erase( "SHOULD_LOGOUT_WEB_APP_USER" );
            context.commit('SET_USER_DETAILS',get_default_user_object());
        },
        async login( context,data ){
            let response_data;
            await context.dispatch('logout');
            await api.post('/token', data, 'text' ).then(response=>{
                if( response.includes( "DOCTYPE" ) ){
                    // something went wrong
                    if( response.includes( "locked out" ) ){
                        response_data = {
                            status:'fail',
                            message: `Your access to this site has been temporarily limited, due to many failed register attempts. Please contact the support.`
                        }
                    }
                }else{
                    try {
                        response_data = JSON.parse( response );
                    }catch( e ){
                        response_data = {
                            status:'fail',
                            message: "Something went wrong. Please try again"
                        }
                    }
                    context.dispatch('maybe_set_token_and_user',response_data);
                }


            }).catch(error=>{
                response_data = {
                    status:false,
                    message:error.message
                }
            })
            return response_data;
        },

        async register( context,data ){
            let response_data;
            await api.post('/register', data ).then(response=>{
                response_data = response;
                if(response_data.status && response_data.status == 'success' ){
                    context.dispatch('maybe_set_token_and_user',response_data);
                }
            }).catch(error=>{
                response_data = {
                    status:"fail",
                    message:error.message
                }
            })
            return response_data;
        },

        async resetPassword( context,data ){
            let response_data;
            await api.post('/reset-password', data ).then(response=>{
                response_data = response;
            }).catch(error=>{
                response_data = {
                    status:"fail",
                    message:error.message
                }
            })
            return response_data;
        },

        async verifyToken (context, token = false ) {
            if( !token ){
                token = context.state.token;
            }
            if( !token ){
                return false;
            }
            const response = await  fetch(`${auth_base_url()}/token/validate`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });

            const response_data = await response.json();

            if (response.status !== 200) {
              console.error(`Error: ${response_data.message}`);
              if( response_data.code == 'jwt_auth_invalid_token' ){
                await context.dispatch('logout');
              }
              return false;
            }

            if( response_data && response_data.user ){{

                let _data = {
                    token:token,
                    user: response_data.user
                };
                // maybe_set_token_and_user( _data )
                context.dispatch('maybe_set_token_and_user',_data);
            }

            }

            return response_data;
        },
        async maybeVerifyToken(context){

            if( context.getters.GET_TOKEN ){
                await context.dispatch( 'verifyToken' ).then( verified=>{
                  if( !verified ){
                    // context.commit('setLoginModalVisible',true);
                  }
                });
              }
        },
        async maybe_get_user_from_token( context ){

            maybe_get_user_from_token(  );
        }

    }
}