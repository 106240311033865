import Notify from 'simple-notify'
import _default from 'vuex'

export default {

    success:( title="",text="", args={} )=>{

        let _default ={
            status:'success',
            title,
            text,
            effect: 'slide',
            speed: 500,
            autoclose: true,
            autotimeout: 3000,
            gap: 10,
            distance: 20,
            type: 1,
            position: 'right top'
        }

        _default = {..._default,...args}

        new Notify( _default )
    },
    warning:( title="",text="",args={} )=>{
        let _default = {
            status:'warning',
            title,
            text,
            effect: 'slide',
            speed: 500,
            autoclose: true,
            autotimeout: 3000,
            gap: 10,
            distance: 20,
            type: 1,
            position: 'right top'
        };
        _default = {..._default,...args}
        return new Notify(_default)
    },
    error:( title="",text="",args={})=>{
        let _default = {
            status:'error',
            title,
            text,
            effect: 'slide',
            speed: 500,
            autoclose: true,
            autotimeout: 3000,
            gap: 10,
            distance: 20,
            type: 1,
            position: 'right top'
        }
        _default = {..._default,...args}
        return new Notify(_default)
    },
}