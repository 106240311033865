import * as Sentry from "@sentry/vue";
// Helper functions for film

export let array_chunk = (arr, chunk_size) => {
    if (chunk_size <= 0) throw "Invalid chunk size";
    var R = [];
    for (var i = 0, len = arr.length; i < len; i += chunk_size)
        R.push(arr.slice(i, i + chunk_size));
    return R;
}
export let formatTime = (seconds) => {
    let min = 0;
    let sec = 0;
    if (seconds) {
        min = Math.floor((seconds / 60));
        sec = Math.floor(seconds - (min * 60));
    }

    if (sec < 10) {
        sec = `0${sec}`;
    };
    return `${min}:${sec}`;
}




let environments = {
    local: {
        app: "http://localhost:8080",
        api_base: "https://development.filmstro.com/wp-json/api/v1/web-app",
        website_url: "https://development.filmstro.com",
        auth_url: "https://development.filmstro.com/wp-json/filmstro-auth/v1",
        access_cookie_name: 'dev_web_app_access'
    },
    dev: {
        app: "https://staging-app.filmstro.com",
        api_base: "https://development.filmstro.com/wp-json/api/v1/web-app",
        website_url: "https://development.filmstro.com",
        auth_url: "https://development.filmstro.com/wp-json/filmstro-auth/v1",
        access_cookie_name: 'dev_web_app_access'
    },
    live: {
        app: "https://app.filmstro.com",
        api_base: "https://filmstro.com/wp-json/api/v1/web-app",
        website_url: "https://filmstro.com",
        auth_url: "https://filmstro.com/wp-json/filmstro-auth/v1",
        access_cookie_name: 'live_web_app_access'
    },
}


// Helper functions for film
export let filmstro_colour = (color) => {
    let filmstro_colour_map = {
        red: "#EE4777",
        blue: "#4C98F2",
        yellow: "#FFBE36",
        black: "#20212A"
    }
    return filmstro_colour_map[color];
}

export let get_api_call = (endpoint) => {

    if (!endpoint.includes('http')) {
        endpoint = `${api_base_url()}${endpoint}`
    }
    return fetch(endpoint, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
}

export let post_api_call = async (endpoint, data) => {

    if (!endpoint.includes('http')) {
        endpoint = `${api_base_url()}${endpoint}`
    }
    return await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            // "x-fs-app":"production"
        }
    });
}


export let cookie = window.Cookie = {
    set: function (name, value, daysToExpire) {
        let domain = "filmstro.com";
        if (location.hostname == 'localhost') {
            domain = location.hostname;
        }
        var cookieValue, d, expire, path;
        expire = '';
        daysToExpire || (daysToExpire = 365);
        d = new Date();
        d.setTime(d.getTime() + (86400000 * parseFloat(daysToExpire)));
        expire = "expires=" + (d.toGMTString());
        path = "path=/";
        cookieValue = "" + (escape(name)) + "=" + (escape(value || '')) + "; " + path + "; " + expire + `;domain=${domain};`;
        return document.cookie = cookieValue;
    },
    get: function (name) {
        var cookie;
        cookie = document.cookie.match(new RegExp('(^|;)\\s*' + escape(name) + '=([^;\\s]+)'));
        if (cookie) {
            return unescape(cookie[2]);
        } else {
            return null;
        }
    },
    getAll: () => {
        var cookies = {};
        if (document.cookie && document.cookie != '') {
            var split = document.cookie.split(';');
            for (var i = 0; i < split.length; i++) {
                var name_value = split[i].split("=");
                name_value[0] = name_value[0].replace(/^ /, '');
                cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(name_value[1]);
            }
        }
        return cookies;
    },
    erase: function (name) {
        var cookie;
        cookie = Cookie.get(name || true);
        Cookie.set(name, '', -1);
        return cookie;
    },
    eraseAll: function () {
        var ca, cookieArray, cookieString, name, singleCookie, _i, _len, _results;
        cookieString = "" + document.cookie;
        cookieArray = cookieString.split("; ");
        _results = [];
        for (_i = 0, _len = cookieArray.length; _i < _len; _i++) {
            ca = cookieArray[_i];
            singleCookie = ca.split("=");
            if (singleCookie.length !== 2) {
                continue;
            }
            name = unescape(singleCookie[0]);
            _results.push(Cookie.erase(name));
        }
        return _results;
    },
    accept: function () {
        if (typeof navigator.cookieEnabled === 'boolean') {
            return navigator.cookieEnabled;
        }
        Cookie.set('_test', '1');
        return Cookie.erase('_test' === '1');
    },
    exists: function (cookieName) {
        var cookieValue;
        cookieValue = Cookie.get(cookieName);
        if (!cookieValue) {
            return false;
        }
        return cookieValue.toString() !== "";
    }
};









export let generate_id = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    //return id of format 'xxxx'-'xxxx'-'xxxx'-'xxxx'-'xxxx'
    return s4() + s4() + s4() + s4() + s4() + s4()
}

export let track_user_action = async (action_name, action_data) => {

    return;
    let user_id = get_user_id();
    if (!user_id) {
        console.info('Not logged in...aborting ');
        return;
    }
    let data = {
        action: action_name,
        data: action_data
    };
    return await post_api_call(`/user-action/${user_id}`, { method: 'POST', ...data })
}


export let app_url = () => {
    let env = get_environment();
    return environments[env].app;
}
export let website_url = () => {
    let env = get_environment();
    return environments[env].website_url;
}

export let account_url = () => {
    let env = get_environment();
    return `${website_url()}/my-account`;
    if (env == 'local' || env == 'dev') {
    }
    if (env == 'live') {
        return 'https://filmstro.com/my-account';
    }
}

export let api_base_url = () => {
    let env = get_environment();
    return environments[env].api_base;
}

export let auth_base_url = () => {
    let env = get_environment();
    return environments[env].auth_url;
}

export let get_access_cookie_name = () => {
    let env = get_environment();
    return environments[env].access_cookie_name;
}


export let get_environment = () => {

    if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.host.includes(':8080')) {
        return 'local';
    } else if (location.hostname.includes("staging-app.filmstro.com") || location.hostname === "dev-app-v2.filmstro.com" || location.hostname === "beta-v2.filmstro.com") {
        return 'dev';
    } else {
        return 'live';
    }
}
/**
 * Custom Filmstro event dispatcher
 * @param {*} name
 * @param {*} data
 */
export let dispatchEvent = (name, data) => {
    let event = new CustomEvent(`filmstro-${name}`, {
        bubbles: false,
        detail: data
    })
    document.body.dispatchEvent(event);
}

export let capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}



export let prepare_session_from_remote = (session) => {
    try {

        if (typeof session['opts'] == 'string' && session['opts'] !== 'null') {
            session['opts'] = JSON.parse(session.opts);
        } else {
            session['opts'] = [];
        }

        if (typeof session['opts2'] == 'string' && session['opts2'] !== 'null') {
            session['opts2'] = JSON.parse(session.opts2);
        } else {
            session['opts2'] = [];
        }

        session['versions']['main'] = typeof session.versions.main == 'string' ? JSON.parse(session.versions.main) : session.versions.main
        session['versions']['version_1'] = typeof session.versions.version_1 == 'string' ? JSON.parse(session.versions.version_1) : session.versions.version_1
        session['versions']['version_2'] = typeof session.versions.version_2 == 'string' ? JSON.parse(session.versions.version_2) : session.versions.version_2
        return session;
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        return false;
    }
}


export let downloadTextFile = (content, name) => {

    const a = document.createElement('a');
    const type = name.split(".").pop();
    a.id = "perlat";
    let blob_url = URL.createObjectURL(new Blob([content], { type: `text/${type === "txt" ? "plain" : type}` }));
    // a.download = name;
    // a.innerHTML = name;
    // saveAs(blob_url, name);

    a.setAttribute("download", name);
    a.setAttribute("href", blob_url);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blob_url);
}

export function format_size(_size) {
    if (!_size) return 0;
    let bytes = _size;
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1) { bytes = bytes + " bytes"; }
    else if (bytes == 1) { bytes = bytes + " byte"; }
    else { bytes = "0 bytes"; }
    return bytes;
}
// export function debounce(func, timeout = 300){
//     let timer;
//     return (...args) => {
//       clearTimeout(timer);
//       timer = setTimeout(() => { func.apply(this, args); }, timeout);
//     };
//   }

export function debounce(func, wait = 500, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


export let show_not_logged_in_modal = () => {

    let url_to_open = `${account_url()}?ref=app_login&redirect=${location.href}`;
    let _el = document.querySelector('#login_button_link');
    if (_el) {
        _el.href = url_to_open;
    }

    toggle_modal('#login_modal');

}
export let setSeo = (title = false, description = false) => {
    if (title) {
        document.title = title
    }
    // if(  )
}

export const isValidUrl = (urlString) => {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e) {
        return false;
    }
}
export const logger = (data, output = false) => {
    window.filmstro_logs = window.filmstro_logs || [];
    window.filmstro_logs.push({
        time: new Date().getTime(),
        data: data
    });
    if (output) {
        console.log(data);
    }
}

export const resizeText = ({ element, elements, parent, minSize = 6, maxSize = 16, step = 1, unit = 'px' }) => {
    (elements || [element]).forEach(el => {
        let i = minSize
        let overflow = false
        while (!overflow && i < maxSize) {
            el.style.fontSize = `${i}${unit}`
            overflow = parent.scrollHeight > parent.clientHeight;

            if (!overflow) i += step
        }

        // revert to last state where no overflow happened
        el.style.fontSize = `${i - step}${unit}`
    })
}

export const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}
export const get_static_music_file_url = (file_name, track = false) => {
    // if we have the old format.
    if (file_name.includes('https://') || file_name.includes('http://')) {
        return file_name.replace('http://', 'https://');
    }
    return `https://themes.assets.filmstro.com/live/static/music-files/${file_name}`;
}

/**
 *
 * @param {*} selector
 * @param {*} container
 */
export const calculateHeightForElement = (selector, container, offset = 100) => {
    let container_el = document.querySelector(container);
    let element = document.querySelector(selector);
    if (container_el && element) {
        let container_box = container_el.getBoundingClientRect();
        element.style.maxHeight = `${container_box.height - offset}px`;
    }
}

export const secondsToTime = function (seconds) {
    var sec_num = parseInt(seconds, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
}

export const maybeGetLocalMp3File = async (theme) => {
    if (typeof window.localforage == 'undefined') {
        return false;
    }
    debugger
    let a = await localfroage.getItem(`static-theme-${theme.theme_id}`).then(d => {
        console.warn(d);
    })
}

export const maybeSaveLocalMp3File = async (theme_name) => {
    if (typeof window.localforage == 'undefined') {
        return false;
    }

    let a = await localfroage.setItem(`static-theme-${theme_name}`, {}).then(d => {
        console.warn(d);
    })
}

export const isInViewport = (element) => {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}


export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function isMinore(str) {
    return (/m/.test(str));
}

export function convert_min_max_values(old_value, old_min, old_max, new_min, new_max) {
    return ((old_value - old_min) / (old_max - old_min)) * (new_max - new_min) + new_min;

}
export function convert_zero_one_value_to_zero_two(value) {
    return convert_min_max_values(value, 0, 1, 0, 2);
}

export function convert_zero_two_value_to_zero_one(value) {
    return convert_min_max_values(value, 0, 2, 0, 1);
}

export function toggleSideBarFilters() {
    document.body.classList.toggle('hide-sidebar-filters');
}

export function CopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
}

export const svgCursorPoint = (evt, svg_el) => {
    var pt = svg_el.createSVGPoint();

    pt.x = evt.clientX;
    pt.y = evt.clientY;
    return pt.matrixTransform(svg_el.getScreenCTM().inverse());
}

export const restrictToZeroOne = (value) => {
    return Math.min(1, Math.max(0, value));
}

export const svgPointToZeroOne = (x, y, svg) => {
    const rect = svg.getBoundingClientRect();
    let h = parseFloat(rect.height);
    let w = parseFloat(rect.width);

    let _x = parseFloat((x / w) );

    let _y = 1 - parseFloat(y / h);

    return {
        x: restrictToZeroOne(_x),
        y: restrictToZeroOne(_y)
    }
}
export const ZeroOneToSVGPoint = (x, y, svg, circle_size = 10) => {
    return {
        x: (parseFloat(svg.style.width) - circle_size) * x,
        y: (parseFloat(svg.style.height) * (1 - y)) - circle_size / 2
    }
}

export const SVG_X_PositionToPercent = (x, svg) => {
    let rect = svg.getBoundingClientRect();
    return (x / rect.width) * 100;
}

export const value_is_between = (a, b1, b2) => {
    if ((a >= b1) && (a <= b2)) { return true; }
    if ((a >= b2) && (a <= b1)) { return true; }
    return false;
}

export const svg_distance = (x1, y1, x2, y2) => {
    var a = x2 - x1;
    var b = y2 - y1;
    return Math.sqrt(a * a + b * b);
}

export const linear_interpolation = (x, y, t) => {
    return (1 - t) * x + t * y;
}

export const interpolate_svg_points = (point1, point2) =>{
    const distance = Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));

    if (distance === 0) {
        return [point1]; // Return the start point if the distance is zero
    }

    const step = 1; // Adjust this value to control smoothness
    const steps = Math.ceil(distance / step);
    const interpolatedPoints = [];

    if (point2.x === point1.x) {
        // Handle vertical line (slope is undefined)
        const yStep = (point2.y - point1.y) / steps;
        for (let i = 0; i <= steps; i++) {
            const x = point1.x;
            const y = point1.y + i * yStep;
            interpolatedPoints.push({ x, y });
        }
    } else {
        const slope = (point2.y - point1.y) / (point2.x - point1.x);
        for (let i = 0; i <= steps; i++) {
            const t = i / steps;
            const x = point1.x + t * (point2.x - point1.x);
            const y = point1.y + slope * (x - point1.x);
            interpolatedPoints.push({ x, y });
        }
    }

    return interpolatedPoints;
}

// Cubic Bezier curve function
export const bezierCurve = (P0, P1, P2, P3, t) => {
    return Math.pow(1 - t, 3) * P0 + 3 * Math.pow(1 - t, 2) * t * P1 + 3 * (1 - t) * Math.pow(t, 2) * P2 + Math.pow(t, 3) * P3;
}
export const bezierCurveInverse = (P0, P1, P2, P3, t) => {
    return Math.pow(1 - t, 3) * P0.y + 3 * Math.pow(1 - t, 2) * t * P1.y + 3 * (1 - t) * Math.pow(t, 2) * P2.y + Math.pow(t, 3) * P3.y;

    return Math.pow(1 - t, 3) * P3 + 3 * Math.pow(1 - t, 2) * t * P2 + 3 * (1 - t) * Math.pow(t, 2) * P1 + Math.pow(t, 3) * P0;
}

export const findPointAtPercentage = (start, end, percentage) =>{
    if (percentage < 0 || percentage > 100) {
        return start;
    }
    let range = end - start;
    let point = start + (range * (percentage / 100));
    return point;
}

export const set_video_time = (time,play = null, offset = false )=>{

    let video_element = document.getElementById('video-el2');
    if( video_element ){
        if( video_element?.readyState >0 ){
            time = Math.max( 0, Math.min( video_element.duration, time ) );
            if( offset !==false ){
                if( offset < 0 ){
                    time += Math.abs( offset );
                }else if( offset > 0 ){
                    time -=  offset ;
                }
            }else{
            }
            video_element.currentTime = time

            if( play ===true && time < video_element.duration ){
                console.log('PLAYING VIDEO')
                video_element.play();
            }

            if( play ===false ){
                video_element.pause();
            }
            video_element.setAttribute('data-videotime', time);

        }
    }
}
//offset = 0 play/pause as normal
//offset = -5 play at 5 seconds
export const maybePlayVideo = ( offset = false ) => {
    console.log( 'maybePlayVideo' );
    let video_element = document.getElementById('video-el2');
    if( video_element && video_element?.readyState >0){
        video_element.play();
    }
}
export const maybePauseVideo = () => {
    let video_element = document.getElementById('video-el2');
    if( video_element ){
        video_element.pause();
    }
}

export function getBrowserName() {
    const userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.includes("Chrome")) {
        browserName = "Chrome";
    } else if (userAgent.includes("Firefox")) {
        browserName = "Firefox";
    } else if (userAgent.includes("Safari")) {
        browserName = "Safari";
    } else if (userAgent.includes("Edg")) {
        browserName = "Edge";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
        browserName = "Internet Explorer";
    } else {
        browserName = "Unknown"; // If the browser is not recognized
    }

    return browserName;
}

export function format_time(seconds){
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    return minutes.toString().padStart(2, '0') + ':' + remainingSeconds.toString().padStart(2, '0');
}

export const highlight = (fuseSearchResult, highlightClassName = 'highlight') => {
    const set = (obj, path,index = null, value) => {
        const pathValue = path.split('.');
        let i;

        for (i = 0; i < pathValue.length - 1; i++) {
          obj = obj[pathValue[i]];
        }
        if( index ){
            obj[pathValue[ index ]] = value;
        }else{
            obj[pathValue[ i ]] = value;
        }
    };

    const generateHighlightedText = (inputText, regions = []) => {
      let content = '';
      let nextUnhighlightedRegionStartingIndex = 0;

      regions.forEach(region => {
        const lastRegionNextIndex = region[1] + 1;

        content += [
          inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
          `<span class="${highlightClassName}">`,
          inputText.substring(region[0], lastRegionNextIndex),
          '</span>',
        ].join('');

        nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
      });

      content += inputText.substring(nextUnhighlightedRegionStartingIndex);

      return content;
    };

    return fuseSearchResult
      .filter(({ matches }) => matches && matches.length)
      .map(( result ) => {
        const highlightedItem = result.item;

        result.matches.forEach((match) => {
          set(highlightedItem, match.key,match.refIndex, generateHighlightedText(match.value, match.indices));
        });
        result.item = highlightedItem
        return result;
      });
  };

  export function splitQuery(query) {
    // Split by comma
    let parts = query.split(',');

    // Initialize an empty array to hold the result
    let result = [];

    // Loop through each part split by comma
    parts.forEach(part => {
        // Trim any extra spaces and push the original part to the result array
        let trimmedPart = part.trim();
        result.push(trimmedPart);

        // Split the part by space and push each word to the result array
        let words = trimmedPart.split(' ');
        words.forEach(word => {
            if (word && !result.includes(word)) { // Avoid empty strings and duplicates
                result.push(word);
            }
        });
    });

    return result;
}

export function waitForUserInteraction( callback = ()=>{} ) {
    return new Promise((resolve) => {
        // Define a variable to track interaction
        let userInteracted = false;

        // Define the interaction events to listen for
        const events = ['click', 'mousemove', 'keydown', 'touchstart'];

        // Create an event listener to detect interaction
        function interactionHandler(e) {
            userInteracted = true;
            resolve(); // Resolve the promise when interaction is detected
            callback();
            clearInterval(checkInterval); // Clear the interval
            // Remove the event listeners after interaction
            events.forEach(event => window.removeEventListener(event, interactionHandler));
        }

        // Attach the event listeners to the window
        events.forEach(event => window.addEventListener(event, interactionHandler));

        // Check for interaction at regular intervals
        const checkInterval = setInterval(() => {
            if (userInteracted) {
                clearInterval(checkInterval); // Clear the interval if interaction was detected
            }
        }, 100); // Check every 100 milliseconds
    });
}