<template>
  <div class="flyout-sidebar">
    <Transition name="fade-bg"> <div class="sidebar-backdrop" @click="store.commit('setFlyoutContentView', false)" v-if="show_flyout_sidebar"></div> </Transition>
    <transition name="slide">
      <div v-if="show_flyout_sidebar" class="flyout-sidebar-content">
        <button @click="store.commit('setFlyoutContentView', false)"
          class="close-flyout-sidebar-button clear-button"><filmstro-icon name="close-icon" /></button>
        <sessions-list v-if="flyout_content_view == 'my-sessions'" />
        <favourites-list v-if="flyout_content_view == 'my-favourites'" />
      </div>
    </transition>
  </div>

  <!-- <div class="flyout-sidebar" :class="{'show': show_flyout_sidebar || flyout_content_view }">
      <div class="flyout-sidebar-content">
        <button @click="store.commit('setFlyoutContentView',false)" class="close-flyout-sidebar-button clear-button"><filmstro-icon name="close-icon"/></button>
        <sessions-list     v-if="flyout_content_view=='my-sessions'" />
        <favourites-list   v-if="flyout_content_view=='my-favourites'" />
      </div>
    </div> -->
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue'
import SessionsList from '@/components/SessionsList.vue'
import FavouritesList from '@/components/FavouritesList.vue'

const store = useStore();
const show_flyout_sidebar = computed(() => store.getters.show_flyout_sidebar)
const flyout_content_view = computed(() => store.getters.flyout_content_view)

</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: slide-in .5s;
}
.slide-leave-active {
  animation: slide-in .5s reverse;
}
@keyframes slide-in {
  0% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0)
  }
}

.fade-bg-enter-active, .fade-bg-leave-active {
  transition: opacity .5s;
}
.fade-bg-enter, .fade-bg-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.close-flyout-sidebar-button {
  margin-left: auto;
  text-align: right;
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  z-index: 100;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, .5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 9999999;
}

.flyout-sidebar-content {
  background: #20202b;
  position: absolute;
  left: 0;
  top: -2px;
  height: 100%;
  z-index: 99999999;
  padding: 20px 10px ;
  width: 410px;
  transform: translateX(0);
  border: var(--filmstro-border);
  border-radius: 4px;
  border-left: 0;
}
h3{
  text-align: center;
  margin-bottom: 20px;
}
</style>