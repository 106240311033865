<template>
    <div>
        <div ref="wrapper"  class='offset-timeline-wrapper' @mouseenter="is_focused = true" @mouseleave="is_focused = false">
            <div class="offset-timeline-body">
                <input @mousedown="handleMouseDown" @mouseup="handleMouseUp" ref="input_slider" type="range" id="offset_number" :min="offsetMin" :max="offsetMax" @input="onSliderInput" v-model="offsetValue">
                <div class="offset-slider">
                    <!-- <div class="track"></div> -->
                    <div class="offset-handle" ref="handle">
                        <button @click="decreaseOffset( 1 )" class="handle-arrow handle-left clear-button"><filmstro-icon name="offset-left" type="image"/></button>
                        <button  class="handle-middle clear-button"><filmstro-icon name="offset-middle" type="image"/></button>
                        <button @click="increaseOffset( 1 )" class="handle-arrow handle-right clear-button"><filmstro-icon name="offset-right" type="image"/></button>
                    </div>
                </div>
                <div class="offset-value-wrapper" v-if="offsetValue!==0">
                    <filmstro-icon v-show="offsetValue<0" name="double-left-arrows" type="image"/>
                    <filmstro-icon v-show="offsetValue>0" name="double-right-arrows" type="image"/>
                    <span> <span>{{Math.abs( offsetValue) }}</span> frames</span>
                </div>
            </div>

        </div>

        <input class="offset-input" type="number" v-model="offsetValue" @keyup="onOffsetInputChange" @mouseup="onOffsetInputChange">
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

import hotkeys from "hotkeys-js";
import { logger } from '@/includes/helpers';

export default {
    props:{
    },
    data(){
        return {
            offsetMin:0,
            offsetMax:0,
            offsetValue:0,
            is_focused:false,
            FPS:24,
        }
    },
    computed:{
        ...mapGetters(['video_element','video_file_info'])
    },
    methods:{
        onOffsetInputChange(){
            this.onSliderInput()
        },
        handleMouseDown(){
            this.$refs.wrapper.classList.add('is-active');
        },
        handleMouseUp(){
            this.$refs.wrapper.classList.remove('is-active');
        },
        setOffset( value ){
            this.offsetValue = parseInt( value ) ;
        },
        increaseOffset( value ){
            let val = parseInt( this.offsetValue) + parseInt( value );
            this.setOffset( val );
            this.onSliderInput()
        },
        decreaseOffset( value ){
            let val = parseInt( this.offsetValue) - parseInt( value );
            this.setOffset( val );
            this.onSliderInput();
        },

        onSliderInput(){

            var percent = ((this.offsetValue - this.offsetMin) / (this.offsetMax - this.offsetMin)) * 100;
            this.$refs.handle.style.left = percent + "%";
            if( isNaN( percent ) ) {
                return;
            }
            if(window.stroInterface){
                stroInterface.setVideoOffset( this.offsetValue );
            }
            this.handleMouseDown();
            this.$emit('offsetChanged',this.offsetValue);
        },
        init(){
            if( !this.video_element ){
                return;
            }
            logger("Initialized OffsetTimeline",true );


            let frames = Math.abs( parseInt(this.video_element.duration * this.FPS ) );
            this.offsetMin = frames * -1;
            this.offsetMax = frames;
            this.setOffset( 0 );
            this.onSliderInput()

        }
    },
    watch:{
        video_file_info:{
            handler:function ( val,old ) {
                logger('video_file_info changed')
                this.init();
            }
        }
    },
    mounted(){
        this.init()
        let $this = this;
        if(this.video_element){
            this.video_element.addEventListener('loadedmetadata',this.init )
        }

        hotkeys('left',()=>{
            if( this.is_focused ){
                this.decreaseOffset( 1 ) ;
            }
        });
        hotkeys('shift+left',()=>{
            if( this.is_focused ){
            this.decreaseOffset( 10 ) ;
            }
        });

        hotkeys('right',()=>{
            if( this.is_focused ){
            this.increaseOffset( 1 ) ;
            }
        });

        hotkeys('shift+right',()=>{
            if( this.is_focused ){
            this.increaseOffset( 10 ) ;
            }
        });
    }
}





</script>




<style lang="scss" scoped>


.offset-timeline-wrapper{
    position:relative;
    background:var(--filmstro-black);
    border:solid 1px var(--filmstro-yellow);
    border-radius: 4px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;

    .offset-timeline-body{
        position:relative;
        display:block;
        height:45px;
        width:100%;
        // background-color:grey;
        overflow-x:hidden;
        padding:5px;
    }

    .offset-value-wrapper {
        position: absolute;
        bottom: 0;
        left: 16px;
        font-size: 9px;
        color: var(--filmstro-yellow);
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events:none;
        margin-bottom: 3px;
        img {
            width: 10px;
            height: 10px;
            object-fit: contain;
        }
    }


    .offset-slider {
        position: relative;
        z-index: 1;
        height: 100%;
        margin: 0 15px;
        .offset-handle{
            position: absolute;
            z-index: 3;
            width: 50px;
            height: 40px;
            border-radius: 50%;
            box-shadow: 0 0 0 0 rgba(98,0,238,.1);
            transition: box-shadow .3s ease-in-out;
            left: 50%;
            transform: translate(-20px, -10px);
            height:calc(100% - 5px);
            top:12px;
            text-align:center;
            display:flex;
            cursor:move;
            border-radius:4px;
            display: flex;
            gap: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 25px;
                height: 25px;
                pointer-events: none;
            }
            .handle-left img,.handle-right img {
                width: 10px;
                height: 15px;
                object-fit: contain;
                opacity: .9;
            }
            .handle-left:hover img {
                // transform: translateX(-2px) scale(1.02);
                opacity: 1;
            }
            .handle-right:hover img {
                // transform: translateX(2px) scale(1.02);
                opacity: 1;
            }

        }
    }

    input[type=range] {
        position: absolute;
        pointer-events: none;
        -webkit-appearance: none;
        z-index: 2;
        height: 30px;
        width: 100%;
        opacity: 0;
    }
    input[type=range]::-webkit-slider-thumb {
        pointer-events: all;
        width: 30px;
        height: 30px;
        border-radius: 0;
        border: 0 none;
        -webkit-appearance: none;
        cursor: pointer;
    }
}

.offset-input{
    border: solid 1px var(--filmstro-gray);
    padding: 2px;
    background: var(--filmstro-white-fade);
    color: white;
    width: 70px;
}






</style>