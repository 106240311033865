<template>
    <div class="favourites-list-wrapper">
        <h3 class="sidebar-title">My Favourites ({{ total_user_favourites }})</h3>
        <audio @timeupdate="onTimeUpdate" controls ref="audio"></audio>
        <div v-if="user_favourites.length">
            <div tag="div" name="fade" class="favourite-list container">
                <div class="favourite" v-for="( favourite,index ) in user_favourites" :key="favourite.theme_id" :class="{'is-playing':favourite_playing && favourite_playing.theme_id == favourite.theme_id}" >
                    <div class="favourite-image" :style="`background-image:url( ${favourite.cover_image} )`" >
                        <button class="music-track-play-toggle" @click="playFavourite( favourite,index )">
							<img  v-show="favourite_playing && favourite_playing.theme_id == favourite.theme_id" src="@/assets/icons/pause-icon.svg" >
							<img  v-show="!favourite_playing || favourite_playing.theme_id !== favourite.theme_id" src="@/assets/icons/play-icon.svg" >
						</button>
                    </div>
                    <div class="favourite-data"> <h3 class="favourite-title">{{favourite.theme_title}}</h3> </div>
                    <div class="favourite-actions">
                        <favourite-toggle :is_favourite="true" :theme_id="favourite.theme_id" />
                        <button @click="openTheme(favourite)" class="clear-button open-theme-link">
                            <img loading="lazy"  src="@/assets/icons/caret-right.svg" >
                        </button>
                    </div>
                    <div class="favourite-theme-progress">
                        <div class="favourite-theme-progress-fill"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="filmstro-message filmstro-warning">
            You have not added any tracks to your favourite list.
        </div>
    </div>
</template>

<script setup>
    import {ref} from 'vue';
    import {get_static_music_file_url,calculateHeightForElement} from '@/includes/helpers';
    const audio = ref( null );
    const audio_is_playin = ref( false );
    const favourite_playing = ref( false );
    let onTimeUpdate = ()=>{
        console.log( 'asds' )
        let active_theme = document.querySelector('.favourite.is-playing');
        if( !active_theme ){
          return;
        }
        let current = audio.value.currentTime / audio.value.duration * 100;
        let progress_bar = active_theme.querySelector('.favourite-theme-progress-fill');
        progress_bar.style.width = `${current}%`;
      }

    const playFavourite = ( favourite )=>{
            if( favourite_playing.value && favourite_playing.value.theme_id == favourite.theme_id && audio_is_playin.value){
                favourite_playing.value = false;
                audio.value.pause();
                audio_is_playin.value = false;
                return;
            }
            if( favourite.track && favourite.track.audio_file && favourite.track.audio_file !== audio.value.src ){
                audio.value.src = get_static_music_file_url( favourite.track.audio_file );
            }
            // if( this.$refs.audio.paused ){
                audio.value.play();
                audio_is_playin.value = true;
                favourite_playing.value = favourite;
            // }
        }
</script>

<script>
import {mapActions,mapGetters} from 'vuex';
import FavouriteToggle from './FavouriteToggle.vue'
export default {
    components:{
        FavouriteToggle
    },
    methods:{
        ...mapActions(['fetchFavourites']),
        openTheme(fav){
            this.$store.commit('setShowFlyoutSideBar',false);
            this.$router.push({
                name:'edit',
                params:{
                    theme:fav.theme_name,
                    version:'main'
                }
            })
        }
    },
    computed:{
        ...mapGetters(['user_favourites']),
        total_user_favourites(){
            if(this.user_favourites){
                return Object.keys( this.user_favourites ).length
            }else{
                return 0;
            }
        }
    },
    async mounted(){

        if( this.CURRENT_USER  && !this.CURRENT_USER.user_id ){
            return;
        }
        let a =calculateHeightForElement('.favourite-list','.favourites-list-wrapper');
        // context.commit('setLoaderMessage','Loading my favourites!');
        // await this.fetchFavourites()
    }
}
</script>



<style scoped lang="scss">

.template-data.main-width {
    height: 380px;
}

.favourites-list-wrapper {
    height: 100%;
    z-index: 1;
    position: relative;
    padding: 15px 25px 0 21px;
    padding-bottom: 25px;
    audio{
        appearance: none;;
        display: none!important;
    }
}

.favourite-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow-y: scroll;
    max-height: 770px;
    position: relative;;
}

.favourite {
    display: flex;
    /* height: 120px; */
    border: solid;
    width: 100%;
    background: #21222B;
    border: var(--filmstro-border);
    border-radius: 12px;
    align-items: center;
    position: relative;
    overflow:hidden;
    margin-right: 10px;
    &:hover{
        border-color:var(--filmstro-red);
    }
    &.is-playing{
        .favourite-theme-progress{
            opacity:1;
            bottom:0;
        }
    }
}

.favourite-image {
    width: 65px;
    height: 65px;
    border-top-left-radius:11px;
    border-bottom-left-radius:11px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    background-position: center;
    &::before{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background: black;
        border-top-left-radius:11px;
        border-bottom-left-radius:11px;
        opacity: 0.4;
    }
}

.favourite-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
}

.favourite-data {
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 13px;
}



h3.favourite-title {
    color: white;
    font-size: 12px;
}

.favourite-actions {
    margin-left: auto;
    height: 100%;
    padding: 10px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.favourite:hover .open-theme-link img{
    transform:translateX(2px);
}

.music-track-play-toggle {
	font-size: 12px;
	border-radius: 50%;
	border: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background: transparent;
	cursor: pointer;
	position: relative;
}

.favourite-theme-progress {
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 4px;
  background: var(--filmstro-gray);
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all .4s ease;
  opacity: 0;
}

.favourite-theme-progress-fill {
  background: var(--filmstro-yellow);
  width: 0;
  height: 100%;
  border-bottom-left-radius:inherit;
}


</style>