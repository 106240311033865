import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    redirect:"/browse"
  },
  // {
  //   path: '/browse/:collection?/:album?',
  //   name: 'old_browse',
  //   // redirect:(a,b,c)=>{
  //   //   debugger
  //   // }
  //   redirect:'/music'
  // },
  {
    path: '/browse/:collection?/:album?',
    name: 'browse',
    props:true,
    component: () => import(/* webpackChunkName: "browse" */ '../views/NewBrowse.vue'),
    beforeRouteUpdate(to, from) { },
    beforeRouteLeave(to, from, next) {
      // called when the route that renders this component is about to
      // be navigated away from.
      // has access to `this` component instance.
    }
  },
  // {
  //   path: '/oldbrowse/:collection?/:album?',
  //   name: 'obrowse',
  //   props:true,
  //   component: () => import(/* webpackChunkName: "browse" */ '../views/Browse.vue'),
  //   beforeRouteUpdate(to, from) { },
  //   beforeRouteLeave(to, from, next) {
  //     // called when the route that renders this component is about to
  //     // be navigated away from.
  //     // has access to `this` component instance.
  //   }
  // },

  {
    path: '/edit/:theme?/:version?/:session_id?',
    name: 'edit',

    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "edit" */ '../views/Edit.vue'),

  },
  // {
  //   path: '/my-sessions',
  //   name: 'my-sessions',
  //   component: () => import(/* webpackChunkName: "my-sessions" */ '../views/MySessions.vue'),
  // },
  // {
  //   path: '/my-favourites',
  //   name: 'my-favourites',
  //   component: () => import(/* webpackChunkName: "my-favourites" */ '../views/MyFavourites.vue')
  // },
  {
    path: "/:catchAll(.*)",
    redirect:"/404"
  },

  {
    path: '/404',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  if( to.name == "browse" ){
    to.meta.transition = "slide-left";
  }else{
    to.meta.transition = "slide-right";
  }
  // to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})



export default router
