<template>
    <modal class="settings-modal" v-show="preferences_modal_visible"  @close="closeModal" :show_close_button="true" :show_the_content="preferences_modal_visible">
        <template v-slot:body>
            <div class="settings-wrapper">
                <h4 class="settings-title text-center">Preferences</h4>
                <div class="setting-block">
                    <div class="setting-top">
                        <label class="toggle-input-label" for="">Sample Rate</label>
                        <div class="toggle-input-wrapper">
                            <label class="toggle-input-label" for="">48000
                            </label>
                            <input id="sample-rate" type="checkbox" class="toggle-input" @click="toggleSampleRate( $event )" :checked="sample_rate == 44100 "/>
                            <label class="toggle-label" for="sample-rate">Sample Rate</label>
                            <span></span>
                            <label class="toggle-input-label" for="">44100</label>
                        </div>
                    </div>
                    <div>
                    <small>Toggle sample rate if your export plays back at a different pitch/speed</small>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
    setup(){
        let store = useStore();
        let closeModal = ()=>{
            store.commit('setPreferencesModalVisible',false);
        }
        let preferences_modal_visible = computed(()=>store.state.preferences_modal_visible)
        let _local_saved_sample_rate = store.getters['edit/sample_rate'];
        let sample_rate = ref( parseInt( _local_saved_sample_rate ) );
        let toggleSampleRate = ( event )=>{
            let element = event.target;

            if( element.checked ){
                sample_rate.value = 44100;
                // wrapper.querySelector('.toggle-input-label span').innerText = 44100;
            }else{
                sample_rate.value = 48000;
                // wrapper.querySelector('.toggle-input-label span').innerText = 48000;
            }
            localStorage.setItem('sample_rate', sample_rate.value );
            store.commit('edit/setSampleRate',  sample_rate.value );
        }

        return {
            preferences_modal_visible,
            sample_rate,
            closeModal,toggleSampleRate
        }
    }
}
</script>

<style lang="scss">
.settings-modal{
    .modal-content{
        background-color: #07131b;
        min-width: 450px;
    }


.modal-dialog {
    max-width: 700px!important;
}


    .setting-block{
        text-align: left;
        label{
            color: #7b848b;
            font-size: 0.875rem;
            font-weight: 600;
        }

   .setting-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-block: 20px;
}

        .toggle-input-wrapper {
    display: inline-flex;
    flex: 1;
    margin-left: auto;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

        small {
    font-size: 12px;
    display: block;
    margin-bottom: 3px;
    margin-top: 4px
}
    }
    .btn-close{
        color: white!important;
        top: 15px;
        right: 20px;
    }
}
</style>