
class Accordion{
    constructor( selector,header_selector=false,body_selector=false, group_selector=false ){

        this.element    = typeof selector == 'string' ? document.querySelector( selector ) : selector;
        if(!this.element){
            throw new Error('Could not find element:',selector);
            return;
        }
        if( !header_selector ){
            header_selector=".accordion-header";
        }
        if(!body_selector){
            body_selector=".accordion-body";
        }
        this.header     = this.element.querySelector( header_selector );
        this.content    = this.element.querySelector( body_selector );
        if( group_selector ){
            this.group_element = this.element.closest( group_selector );
            if(this.group_element){
                this.group_element.classList.add('accordion-group');
            }
        }
        this.init();
    }
    init = ()=>{
        this.attachEvents();
        this.element.classList.add('accordion');
        if(this.header){
            this.header.classList.add('accordion-toggle');
        }
        if(this.content){
            this.content.classList.add('accordion-body');
        }
    }
    attachEvents = ()=>{
        if(this.header){
            this.header.addEventListener('click',this.toggleAccordion,false)
        }
        if(this.content){
            // this.header.addEventListener('click','toggleAccordion',false)
        }
    }
    openAccordion=()=>{
        this.element.classList.add('is-open');
    }
    closeAccordion=()=>{
        this.element.classList.remove('is-open');
    }
    toggleAccordion=()=>{
        let is_open = this.element.getAttribute('data-open');
        if(this.group_element){
            this.group_element.querySelectorAll('.accordion')
            .forEach(accordion=>{
                if(accordion !== this.element){
                    accordion.classList.remove('is-open')
                }
            })
        }

        this.element.classList.toggle('is-open');
        this.element.setAttribute('data-open',!is_open);
    }
}

export default Accordion;